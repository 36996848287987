/**
 * SINGLE-SPA root config
 * arthur @kalchuka
 * 
 * 
 */
import { registerApplication, start } from "single-spa";
import PubSub from "pubsub-js";
const publish = (event, data) => PubSub.publish(event, data)
const subscribe = (event, callback) => PubSub.subscribe(event, callback)
registerApplication({
  name: "@appmart/auth",
  app: () =>
    System.import(
      "@Appmart/auth"
    ),
  activeWhen: ["#/auth"],
  customProps: {
    publish,
    subscribe,
  }
});
//// Register and load Dashboard parcel if the route is /dahsboard/
registerApplication({
  name: "@appmart/dashboard",
  app: () =>
    System.import(
      "@Appmart/dashboard"
    ),
  activeWhen: ["#/dashboard","#/bursary","#/admin","#/admissions"],
  customProps: {
    publish,
    subscribe,
  }
});
//// Register and load Dashboard parcel if the route is /dahsboard/
registerApplication({
  name: "@appmart/bursary",
  app: () =>
    System.import(
      "@Appmart/bursary"
    ),
  activeWhen: ["/bursary"],
  customProps: {
    publish,
    subscribe,
  }
});

//// Register and load Dashboard parcel if the route is /dahsboard/
registerApplication({
  name: "@appmart/mainadmin",
  app: () =>
    System.import(
      "@Appmart/mainadmin"
    ),
  activeWhen: ["/admin"],
  customProps: {
    publish,
    subscribe,
  }
});

//// Register and load Dashboard parcel if the route is /dahsboard/
registerApplication({
  name: "@appmart/admissions",
  app: () =>
    System.import(
      "@Appmart/admissions"
    ),
  activeWhen: ["#/admissions","#/biometrics"],
  customProps: {
    publish,
    subscribe,
  }
});
start({
  urlRerouteOnly: true,
})

